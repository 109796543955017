import HeaderLine from '../header/HeaderLine'
import {ApplicationState, setApplicationState} from "../../../store/ApplicationSlice";
import {useDispatch} from "react-redux";
import {AppDispatch, useAppSelector} from "../../../store/ReduxStore";
import {setPosition} from "../../../store/workflow/MapSlice";
import {selectProductIndex} from "../../../store/workflow/ProductSlice";

const Breadcrumb = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { userProductName } = useAppSelector(state => state.productReducer);

    return (
        <HeaderLine style={{height: 48, borderTop: "1px solid #E5E5E5"}} className="bread">
            <ol className="breadcrumb">
                <li className="breadcrumb-item" onClick={() => {
                    dispatch(setPosition(undefined));
                    dispatch(setApplicationState(ApplicationState.PRODUCT_SELECTION_SCREEN));
                    dispatch(selectProductIndex(-1))
                }} style={{cursor: 'pointer'}}>Startseite</li>
                {userProductName ? <li className="breadcrumb-item active" aria-current="page">{userProductName}</li> : null}
            </ol>
        </HeaderLine>
    )
}

export default Breadcrumb