import {useEffect, useState} from "react";
import { useAuth } from "react-oidc-context";
import { logoutAction } from "./UserInformationBox";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/ReduxStore";

const SessionTimer = () => {
    const auth = useAuth();
    const SECONDS_PER_MINUTE = 60
    const [sessionTimeLeft, setSessionTimeLeft] = useState(120)
    const dispatch = useDispatch<AppDispatch>();

    const calculateSessionMinutesLeft = (expirationDateSeconds?: number) => {
        if (expirationDateSeconds === undefined) {
            return 0;
        }
        return Math.ceil(expirationDateSeconds / SECONDS_PER_MINUTE)
    }

    useEffect(() => {
        if (auth.isAuthenticated) {
            setSessionTimeLeft(calculateSessionMinutesLeft(auth.user?.expires_in))
            const timer = setInterval(() => {
                setSessionTimeLeft(calculateSessionMinutesLeft(auth.user?.expires_in))
                if (auth.user?.expired) {
                    clearInterval(timer);
                    logoutAction(auth, dispatch)
                }
            }, SECONDS_PER_MINUTE);
            return () => {
                clearInterval(timer);
            }
        }           
    }, [auth.isAuthenticated]);

    return (
        <div>
            <div className="text-muted text-nowrap">verbleibende Sitzungszeit: {<span className="text-black">{sessionTimeLeft} Minuten</span>} </div>
        </div>
    )
}


export default SessionTimer;