import TileTemplate from "../../../ui/common/TileTemplate";
import classes from "./VideoBox.module.css";
import {ScaleIconActionEdit, ScaleList, ScaleListItem} from "@telekom/scale-components-react";
import {ApplicationState, goNext} from "../../../../store/ApplicationSlice";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../store/ReduxStore";
import {useState} from "react";
import EditableProductNameBox from "./EditableProductNameBox";

const VideoBox = ({applicationState} : {applicationState: ApplicationState}) => {
    const showSearchPanel = applicationState === ApplicationState.SEARCH_SCREEN;
    const productName = useAppSelector((state) => state.productReducer.userProductName);
    const { isPositionValid } = useAppSelector((state) => state.mapReducer);
    const dispatch = useDispatch();
    const [showEditField, setShowEditField] = useState(false);

    if (!isPositionValid) return <></>;

    return (
        <TileTemplate className={classes.container}>
            {
                showEditField ?
                    <EditableProductNameBox setShowEditField={setShowEditField}/>
                    :
                    <div className={classes.header}>
                        <div className={`teleNeo32Bold`} style={{width: "88%", overflow: "hidden", textOverflow: "ellipsis"}}>{productName}</div>
                        <ScaleIconActionEdit className={classes.editIcon} onClick={() => setShowEditField(!showEditField)}/>
                    </div>
            }

            {
                showSearchPanel &&
                <>
                    <div className={classes.description}>
                        <div className={`teleNeo16 ${classes.textBody}`}>Up- & Download</div>
                        <ScaleList>
                            <ScaleListItem className={`teleNeo16 ${classes.listItem}`}>angestrebte Mindest-Upload-Rate beträgt 8 Mbit/s</ScaleListItem>
                            <ScaleListItem className={`teleNeo16 ${classes.listItem}`}>angestrebte Mindest-Download-Rate beträgt 3 Mbit/s außerhalb von Gebäuden</ScaleListItem>
                        </ScaleList>
                    </div>
                    <button type="submit" className={`btn telekom-button-wide`} onClick={() => dispatch(goNext())}>Zeitraum auswählen</button>
                </>
            }
        </TileTemplate>
    )
}

export default VideoBox