import {createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import { RootState } from './ReduxStore';
import { getCityFromPayload, getReverseGeocodePayload, getStreetAndNumber, ReverseGeocodePayload } from './workflow/MapSlice';
import {Address, UserOrder} from "./workflow/models";

interface UserOrdersContext {
    userOrdersList: UserOrder[];
    currentOrderNumber?: string;
}

const initialState:UserOrdersContext = {
    userOrdersList: [],
    currentOrderNumber: undefined
}

export const setCurrentOrder = createAsyncThunk<ReverseGeocodePayload | undefined, string | undefined, {state: RootState}>('userOrders/setCurrentOrder',
    async (noOrder, {dispatch, getState}) => {
  dispatch(setUserOrdersNumber(noOrder));
  const currentOrder = getCurrentOrder(getState().userOrdersReducer, noOrder);
  if (!currentOrder?.address && currentOrder?.position) {
    return getReverseGeocodePayload(currentOrder.position);
  }
})

export const getCurrentOrderSelector: (state: RootState) => UserOrder | undefined = createSelector(
  [(rootState: RootState) => rootState.userOrdersReducer],
  (userOrdersContext) => {
      return getCurrentOrder(userOrdersContext, userOrdersContext.currentOrderNumber)
  }
)

const getCurrentOrder = (userOrdersContext: UserOrdersContext, noOrder?: string) => {
  return userOrdersContext.userOrdersList?.find(elem => elem.noOrder === noOrder);
}

const userOrdersSlice = createSlice({
  name: 'userOrders',
  initialState,
  reducers: {
      setUserOrdersList(state, action: PayloadAction<UserOrder[]>){
          state.userOrdersList = action.payload;
      },
      setUserOrdersNumber(state, action: PayloadAction<string | undefined>){
          state.currentOrderNumber = action.payload;
      }
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentOrder.fulfilled, (state, action) => {
      if (action.payload) {
        const updated = state.userOrdersList.map(order => {
          if (order.noOrder === state.currentOrderNumber) {
            return { ...order,
              address: reverseGeocodeToAddress(action.payload)
            };
          }
          return order;
        });
      
        return {
          ...state,
          userOrdersList: updated
        };
      }
    })
    builder.addCase(setCurrentOrder.rejected, () => {
      console.error("reverse geocoding failure")
    })
  },
})

const reverseGeocodeToAddress = (reverseGeocode?: ReverseGeocodePayload): Address => {
  if (!reverseGeocode) {
    return {
      city: '',
      street: '',
      zipCode: ''
    }
  }
  return {
    city: getCityFromPayload(reverseGeocode) ?? "",
    street: `${getStreetAndNumber(reverseGeocode.address)}`,
    zipCode: reverseGeocode.address.postcode
  }
}

export const { setUserOrdersList, setUserOrdersNumber } = userOrdersSlice.actions
export default userOrdersSlice.reducer