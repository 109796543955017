import {Point} from '../../../../store/workflow/models';
import {useAppSelector} from '../../../../store/ReduxStore';
import TileTemplate from '../../../ui/common/TileTemplate'
import classes from "./SelectedPlace.module.css";
import { reverseGeocodeToString } from '../../../../store/workflow/MapSlice';

const SelectedPlace = () => {
  const { position, geocodeAddress } = useAppSelector(state => state.mapReducer)

  return (
    <TileTemplate className={classes.container}>
        <div className={`${classes.title} teleNeo16`}>Ort</div>
        <div className={classes.selectedPlace}>
          <div>{reverseGeocodeToString(geocodeAddress, true)}</div>
          <div>{getLat(position)}, {getLng(position)}</div>
        </div>
    </TileTemplate>
  )
}

export const getLat = (position: Point | undefined): string => {
  return getCoordinate(position?.lat, ["N", "S"]);
}

export const getLng = (position: Point | undefined): string => {
  return getCoordinate(position?.lng, ["O", "W"]);
}

const getCoordinate = (coordinate: number | undefined, symbols: [string, string]): string => {
  if (!coordinate) return "";
  const symbol = coordinate > 0 ? symbols[0] : symbols[1];
  return `${Math.abs(coordinate).toPrecision(7).replace(".", ",")}° ${symbol}`;
}

export default SelectedPlace