import { useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store/ApplicationSlice";
import { getOrderSelector, RootState, useAppSelector } from "../../../store/ReduxStore";
import { Order, OrderResponse } from "../../../store/workflow/models";
import ConfirmationModal from "../confirmation/ConfirmationModal";
import SendOrderModal from "./SendOrderModal";

const SendOrderComponent = ({applicationState} : {applicationState: ApplicationState}) => {
  const showSendOrderModal = applicationState === ApplicationState.SEND_ORDER_SCREEN;
  const [response, setResponse] = useState<OrderResponse | undefined>();
  const order = useSelector<RootState, Order>(getOrderSelector);
  const { geocodeAddress } = useAppSelector((state) => state.mapReducer);

  return (
    <>
      {
        showSendOrderModal ?
        (response === undefined ?
          <SendOrderModal setResponse={setResponse} order={order} address={geocodeAddress} />
          :
          <ConfirmationModal response={response} order={order} address={geocodeAddress} />
        )
        : ""
      }
    </>
  )
}

export default SendOrderComponent