import {ApplicationState} from "../../store/ApplicationSlice";
import classes from "./DashboardPage.module.css";
import "mapbox-gl/dist/mapbox-gl.css"
import {UserOrder} from "../../store/workflow/models";
import DetailCard from "./DetailCard";
import OrdersTable from "./OrdersTable";
import {ScaleLoadingSpinner} from "@telekom/scale-components-react";
import {RootState, useAppSelector} from "../../store/ReduxStore";
import {getCurrentOrderSelector} from "../../store/UserOrdersSlice";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

const DashboardPage = ({applicationState}: { applicationState: ApplicationState }) => {
    const [showSpinner, setShowSpinner] = useState(true);
    const showDashboardPage = applicationState === ApplicationState.DASHBOARD_SCREEN;
    const { userOrdersList } = useAppSelector((state) => {
        return state.userOrdersReducer;
    });
    const currentOrder = useSelector<RootState, UserOrder | undefined>(getCurrentOrderSelector) ?? {id: '', name: '', noOrder: '', description: ''};

    useEffect(() => {
        const handleWindowLoad = () => {
            setTimeout(() => {
                setShowSpinner(false)
            }, 5000);

        }
        if (document.readyState === 'complete') {
            handleWindowLoad();
        } else {
            window.addEventListener('load', handleWindowLoad);
        }

        return () => {
            window.removeEventListener('load', handleWindowLoad);
        };
    }, []);

    return showDashboardPage ?
            <div className={classes.mainContainer}>
                <div className={classes.headerContainer}>
                    <p className={`teleNeo42 ${classes.headerText}`}>5G Dashboard</p>
                    <p className={`teleNeo16 ${classes.headerText}`}>Buchungsübersicht</p>
                </div>

                <div className={classes.diagramsContainer}>

                    {
                        showSpinner ? <div className={classes.spinnerContainer}><ScaleLoadingSpinner size="large" text="Loading..."/></div> :
                            <>
                                {
                                    userOrdersList.length !== 0 ?
                                        <div className={classes.cardContainer}>
                                            <DetailCard currentOrder={currentOrder}/> </div> : null

                                }

                                <div className={classes.tableContainer}>
                                    <OrdersTable userOrdersList={userOrdersList} />
                                </div>
                            </>
                    }

                </div>
            </div>
        : null
}

export default DashboardPage