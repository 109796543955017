import { CSSProperties } from "react"
import { PropsReactChildren } from "../../common/model"
import classes from "./TileTemplate.module.css"

const TileTemplate = ({ children, style, className } : { children: PropsReactChildren, style?: CSSProperties, className?: string }) => {
  return (
    <div className={`${classes.tile} ${className !== undefined ? className : "" }`} style={style}>
      { children }
    </div>
  )
}

export default TileTemplate