import { useEffect, useState } from 'react';
import { getVersion } from '../../api/backend/versionApi';
import { telekomLogo } from '../../assets/Consts'
import HorizontalBar from './common/HorizontalBar'
import { useAuth } from 'react-oidc-context';

const Footer = () => {
  const [buildVersion, setBuildVesrion] = useState('');
  const {isAuthenticated, user} = useAuth();

  useEffect(() => {
    if (isAuthenticated && user?.access_token) {
      getVersion()
          .then(res => {
            if (res.buildVersion) {
                const commitId = res.git?.commit?.id
                console.log(`Build version of backend: ${res.buildVersion} ${commitId}`);
                setBuildVesrion(`${res.buildVersion}${commitId ? ` ${commitId}` : ""}`);
            }
          })
          .catch((e) => {
            console.error("Fetch version error " + e?.status);
          }
      );
    } else {
      setBuildVesrion("");
    }
  }, [isAuthenticated, user]);

  return (
    <footer className="footer">
        <HorizontalBar>
            <img src={telekomLogo} alt="Telekom logo"/>
            <div className="text-center">© Deutsche Telekom AG</div>
            <div>{printVersion("frontend", process.env.REACT_APP_VERSION)} {printVersion("backend", buildVersion)}</div>
        </HorizontalBar>
    </footer>
  )
}

const printVersion = (type: string, version?: string) => version ? <div>{version} ({type})</div>: ""

export default Footer