import { CSSProperties } from "react"
import { PropsReactChildren } from "../../common/model"
import HorizontalBar from "../common/HorizontalBar"

const HeaderLine = ({children, style, className} : {children: PropsReactChildren, style?: CSSProperties, className?: string}) => {
  return (
    <header>
      <HorizontalBar style={style} className={className}>
        { children }
      </HorizontalBar>
    </header>
  )
}

export default HeaderLine