import * as api from "../api";
import {authHeader} from "../apiHeader";
import {SimCardDTO, CustomerDTO} from "../../store/workflow/models";

const customerUrl = `${process.env.REACT_APP_BACKEND_API_URL}/customer`;

export const getSimCards = (customerNr?: string, productOfferingId?: string) => api.authorizedPost<SimCardDTO[]>(`${customerUrl}/simcards`, authHeader(), {
    customerNr,
    productOfferingId
});

export const getCustomers = () => api.authorizedGet<CustomerDTO[]>(`${customerUrl}/list`, authHeader());
