import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'universal-cookie';
import { resetWorkflow } from '../CommonActions';
import { pick, Point } from './models';
import { MapboxRequest } from '../../api/backend/tmapApi';

const COOKIE_NAME = "mapHistory";

const getHistoryFromCookie = ():string[] => {
  const cookies = new Cookies();
  const cookie = cookies.get(COOKIE_NAME);
  if (cookie === undefined) {
    return []
  }
  return cookie;
}

export interface MapContextType {
    position?: Point;
    mapboxRequest?: MapboxRequest;
    isPositionValid: boolean;
    geocodeAddress?: GeocodeAddress;
    searchHistory: string[];
    hideHistory: boolean;
    searchQuery: string | null;
    suggestionsAmount: number;
}

export interface GeocodeAddress {
  house_number: string;
  road: string;
  city?: string;
  postcode: string;
  country: string;
  town?: string;
  village?: string;
}

export interface ReverseGeocodePayload {
  category:	string;
  type:	string;
  addresstype: string;
  address: GeocodeAddress;
}

export const initialState:MapContextType = {
    searchHistory: getHistoryFromCookie(),
    hideHistory: false,
    searchQuery: null,
    isPositionValid: false,
    suggestionsAmount: 0
}

export const getReverseGeocodingUrl = (point: Point): string => {
  return `${process.env.REACT_APP_REVERSE_GEOCODING_URL}?format=jsonv2&lat=${point.lat}&lon=${point.lng}`
}

export const setPositionAndAddress = createAsyncThunk<void, Point>('map/setPositionAndAddress',
    async (point, { dispatch }) => {
  dispatch(setPosition(point));
  try {
    const reverseGeocode = await getReverseGeocodePayload(point);

    const geocodeAddress = pick(reverseGeocode.address, 'city', 'house_number', 'road', 'postcode', 'country');
    geocodeAddress.city = getCityFromPayload(reverseGeocode);
    dispatch(setGeocodeAddress(geocodeAddress));
  } catch (error) {
    console.error(`error with getting location for point: [${point.lat}, ${point.lng}]`);
    dispatch(setGeocodeAddress(undefined));
  }
})

export const getReverseGeocodePayload = async (point: Point): Promise<ReverseGeocodePayload> => {
  const response: Response = await fetch(getReverseGeocodingUrl(point));
  return (await response.json()) as ReverseGeocodePayload;
}

export const getStreetAndNumber = (address?: GeocodeAddress) => {
  const streetNumber = address?.house_number ? ` ${address.house_number}` : "";
  return address?.road ? `${address.road}${streetNumber}` : ""
}

export const reverseGeocodeToString = (address?: GeocodeAddress, addBreakLine?: boolean) => {
  if (address) {
    const street = address.road ? <>{getStreetAndNumber(address)}, {addBreakLine ? <br /> : ""}</> : ""
    return <>{street}{address.postcode} {address.city}</>;
  }
  
  console.log("No address found");
  return <></>;
}

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setPosition(state, action: PayloadAction<Point | undefined>) {
      state.position = action.payload;
      state.mapboxRequest = undefined;
    },
    setGeocodeAddress(state, action: PayloadAction<GeocodeAddress | undefined>) {
      state.geocodeAddress = action.payload;
    },
    addToSearchHistory(state, action: PayloadAction<string>) {
      if (state.searchHistory.indexOf(action.payload) === -1) {
        if (state.searchHistory?.length === 5) {
          state.searchHistory.shift();
        }
        state.searchHistory.push(action.payload);

        const cookies = new Cookies();
        cookies.set(COOKIE_NAME, state.searchHistory);
      }
    },
    hideHistory(state, action: PayloadAction<boolean>) {
      state.hideHistory = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string | null>) {
      state.searchQuery = action.payload;
    },
    setSuggestionsAmount(state, action: PayloadAction<number>) {
      state.suggestionsAmount = action.payload;
    },
    setIsPositionValid(state, action: PayloadAction<boolean>) {
      state.isPositionValid = action.payload;
    },
    setMapboxRequest(state, action: PayloadAction<MapboxRequest>) {
      state.position = action.payload.point;
      state.mapboxRequest = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetWorkflow, () => initialState)
  },
})

export const getCityFromPayload = (payload: ReverseGeocodePayload) => {
  if (payload.address.city) {
    return payload.address.city;
  } else if (payload.address.town) {
    return payload.address.town;
  } else if (payload.address.village) {
    return payload.address.village;
  }
}

export const { setPosition, setGeocodeAddress, addToSearchHistory, setSearchQuery, hideHistory, setSuggestionsAmount, setIsPositionValid, setMapboxRequest } = mapSlice.actions
export default mapSlice.reducer