import TileTemplate from './TileTemplate'
import classes from "./ModalTemplate.module.css";
import { PropsReactChildren } from '../../common/model';

const ModalTemplate = ({ children, className } : { children: PropsReactChildren, className?: string }) => {
  return (
    <TileTemplate className={`${classes.container} ${className ?? ""}`}>
      { children }
    </TileTemplate>
  )
}

export default ModalTemplate