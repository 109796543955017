import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface NotificationState {
    open?: boolean;
    cssType?: string;
    message?: string;
}

export const notificationInitialState: NotificationState = {
    open: false,
    cssType: "alert-warning",
    message: ""
};

export const notificationSlice = createSlice({
    name: "notification",
    initialState: notificationInitialState,
    reducers: {
        addNotification: (_state, action: PayloadAction<NotificationState>) => ({
            ...notificationInitialState,
            ...action.payload,
            open: true
        }),
        clearNotification: (state) => ({ ...state, open: false })
    }
});

export const notificationActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;