import classes from "./HourPicker.module.css";
import {ScaleTextField} from "@telekom/scale-components-react";
import {Dispatch, SetStateAction} from "react";
import { ScaleTextFieldCustomEvent } from "@telekom/scale-components";
import { InputChangeEventDetail } from "@telekom/scale-components/dist/types/components/input/input";

const HourPicker = ({spanValue, label, timeValue, setSelectedTime, invalid}:
                        {spanValue: string, label: string, timeValue: string, setSelectedTime: Dispatch<SetStateAction<string>>, invalid: boolean}) => {

    const handleChange = (event: ScaleTextFieldCustomEvent<InputChangeEventDetail>) => {
        if (event.detail && event.detail.value) {
            setSelectedTime(event.detail.value.toString());

        }
    };

    return (
        <div className={classes.container}>
            <span className='eyebrow teleNeo16'>{spanValue}</span>
                {/*Bug in scale library from telekome - in documentation we can choose time type, but in the code we can't !!!*/}
                <ScaleTextField type="time"
                                className={classes.hourPicker}
                                label={label}
                                step="60"
                                value={timeValue}
                                onScaleChange={handleChange}
                                invalid={invalid}
                ></ScaleTextField>
        </div>
    )
}

export default HourPicker