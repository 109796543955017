import {dashboardIcon} from "../../../assets/Consts";
import classes from "./UserInformationBox.module.css";
import {useDispatch} from "react-redux";
import {AppDispatch, RootState} from "../../../store/ReduxStore";
import {ScaleDivider} from "@telekom/scale-components-react";
import {goToDashboard} from "../../../store/ApplicationSliceActions";
import { AuthContextProps, useAuth } from "react-oidc-context";
import { RESET_WORKFLOW } from "../../../store/CommonActions";
import { Action, CombinedState, ThunkDispatch } from "@reduxjs/toolkit";

const UserInformationBox = () => {
    const auth = useAuth();
    const dispatch = useDispatch<AppDispatch>();

    return (
        <div className={classes.userBox}>
            <div className={classes.userTitle}>
                <div className={`title-text {classes.userText}`}>User</div>
                <div className={`teleNeo16Light {classes.emailText}`}>{auth.user?.profile.email}</div>
            </div>
            <ScaleDivider />
            <div className={classes.menuBox}>
                <div className={classes.menuItem} onClick={() =>  dispatch(goToDashboard())}>
                    <div className={classes.icon}>
                        <img src={dashboardIcon} alt={"dashboardIcon"}/>
                    </div>
                    <div className={`.teleNeo16 {classes.itemMenuText}`}>Dashboard</div>
                </div>
            </div>
            <ScaleDivider />
            <div style={{padding: '24px 24.5px'}}><button className={`btn-primary-outline teleNeo16 ${classes.logoutButton}`} onClick={() => logoutAction(auth, dispatch)}>Logout</button></div>

        </div>
    )
}

export const logoutAction = async (auth: AuthContextProps, dispatch: ThunkDispatch<CombinedState<RootState>, undefined, Action>) => {
    await auth.signoutSilent();
    dispatch({type: RESET_WORKFLOW})
}

export default UserInformationBox