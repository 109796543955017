import {useSelector} from 'react-redux';
import {ApplicationState} from '../../../store/ApplicationSlice';
import {Order} from '../../../store/workflow/models';
import {getOrderSelector, RootState} from '../../../store/ReduxStore';
import ActionResult from '../../common/ActionResult';
import OrderSummaryTile from './OrderSummaryTile';
import PriceTile from './PriceTile';

const SummaryArea = ({applicationState} : {applicationState: ApplicationState}) => {
  const showSummaryArea = [ApplicationState.SUMMARY_SCREEN, ApplicationState.SEND_ORDER_SCREEN].includes(applicationState);
  const order = useSelector<RootState, Order>(getOrderSelector);

  return (
    <>
      {
        showSummaryArea &&
        <>
          <PriceTile selectedProduct={order.selectedProduct}/>
          <OrderSummaryTile orderDate={order.dateOrder} simCard={order.simCard} />
          <ActionResult isSuccess={true}>
            Service für ausgewählten<br/>
            Zeitraum verfügbar.
          </ActionResult>
        </>
      }
    </>
  )
}

export default SummaryArea