import {ScaleIconActionEdit} from "@telekom/scale-components-react";
import {CSSProperties, useState} from "react";
import {useDispatch} from "react-redux";
import {sendOrder} from "../../../api/backend/orderApi";
import {simCard} from "../../../assets/Consts";
import {goBack} from "../../../store/ApplicationSlice";
import {GeocodeAddress} from "../../../store/workflow/MapSlice";
import {Order, OrderResponse} from "../../../store/workflow/models";
import OrderDetails from "../../order/OrderDetails";
import HorizontalLine from "../../ui/common/HorizontalLine";
import ModalTemplate from "../../ui/common/ModalTemplate";
import {formatMoney} from "../../utils/OrderDetailsFormatter";
import classes from "./SendOrderModal.module.css";
import {useTranslation} from "react-i18next";
import EditableProductNameBox from "../search/videoBox/EditableProductNameBox";
import {useAppSelector} from "../../../store/ReduxStore";
import moment from "moment-timezone";
import { ValidationError } from "../../../api/retryUtils";
import { humanizeDurationIso8601 } from "../../../utils/displayUtils";

const SendOrderModal = ({setResponse, order, address}:
    {setResponse: (response: OrderResponse) => void, order: Order, address?: GeocodeAddress}) => {
  const productName = order.selectedProduct?.name !== undefined ? order.selectedProduct?.name : "";
  const userProductName = useAppSelector((state) => state.productReducer.userProductName) ?? "";
  const price = order.selectedProduct?.price ?? 0;
  const lengthBillingUnit = order.selectedProduct?.lengthBillingUnit !== undefined ? moment.duration(order.selectedProduct?.lengthBillingUnit.value).asMinutes() : 0;
  const [orderError, setOrderError] = useState<string | undefined | null>(undefined)
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showEditField, setShowEditField] = useState(false);

  const confirmHandler = async () => {
    try {
        setResponse(await sendOrder(order));
      } catch (e) {
        const validationError = e as ValidationError
        if (validationError?.body?.errorCode) {
          const { params, errorCode } = validationError.body
          setOrderError(t(errorCode, humanizeDurationIso8601(params)))
        } else {
          setOrderError(t("error.default"))
        }
      }
    }


  return (
    <ModalTemplate>
      <div className="teleNeo16Bold">Ihre Buchungsanfrage</div>
      <div className="teleNeo32Bold" style={{marginTop: 4}}>{productName}</div>

      <HorizontalLine />

      {
        orderError ?
        <div className={`${classes.error} alert alert-danger`} role="alert">
          <ul>
            <li key={'errorCode'}>{orderError}</li>
          </ul>
        </div> : null
      }

      <div className={classes.orderDetailsContainer}>
        <div className={classes.simCardColumn}>
          <img src={simCard} alt="Sim card logo" />
        </div>
        <div className={classes.orderDetailsColumn}>
          <HeaderCostLine
            title={productName}
            value={price}
            unit={lengthBillingUnit}
            style={{marginBottom: 12}} />
        </div>
      </div>

      <HorizontalLine />
      {
        showEditField ?
            <EditableProductNameBox setShowEditField={setShowEditField}/>
            :
            <div className={classes.orderDetailsContainer}>
                <div className={`teleNeo32Bold`}>{userProductName}</div>
                <ScaleIconActionEdit role='icon' className={classes.editIcon} onClick={() => setShowEditField(!showEditField)}/>
            </div>
      }

      <OrderDetails order={order} padding={12} address={address} />

      <div style={{marginTop: 32, float: "right"}}>
        <button className="telekom-button-fat btn" onClick={() => dispatch(goBack())}>Abbrechen</button>
        <button className="telekom-button-magenta telekom-button-fat btn" onClick={confirmHandler} style={{marginLeft: 16}}>Kostenpflichtig bestellen</button>
      </div>
    </ModalTemplate>
  )
}

//Commented, it can be used later
// const CostLine = ({title, value} : {title: string, value: number}) => {
//   return (
//       <div className='teleNeo16BoldLight' style={{marginTop: 4}}>
//           <span>
//               {title}
//           </span>
//           <span style={{float: "right"}}>
//               {formatMoney(value)} €
//           </span>
//       </div>
//   )
// }

const HeaderCostLine = ({title, value, unit, style} : {title: string, value: number, unit: number, style?: CSSProperties}) => {
  return (
      <div className='teleNeo24Bold' style={style}>
          <span>
              {title}
          </span>
          <span className="magenta" style={{float: "right"}}>
              {formatMoney(value)} € / {unit} Min. <br/>
          </span>
      </div>
  )
}

export default SendOrderModal