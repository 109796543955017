import * as api from "../api";
import { authHeader } from "../apiHeader";

const versionUrl = `${process.env.REACT_APP_BACKEND_URL}/actuator/info`;
type ActuatorInfo = {
    buildVersion: string;
    git?: {
        commit?: {
            id: string
        }
    }
}

export const getVersion = () => api.authorizedGet<ActuatorInfo>(versionUrl, authHeader());