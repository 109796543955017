import { CSSProperties } from 'react'
import { PropsReactChildren } from '../../common/model'

const HorizontalBar = ({children, style, className} : {children: PropsReactChildren, style?: CSSProperties, className?: string}) => {
  return (
    <div className={`horizontal-bar ${className ? className : ""}`} style={style}>
            <nav className="navbar">
                <div className="container-fluid ps-0">
                    {children}
                </div>
            </nav>
    </div>
  )
}

export default HorizontalBar