import { useEffect, useRef } from "react";
import { Point } from "../../store/workflow/models";
import { MapSnapshot } from "./mapWrapper/MapSnapshot";

const MapSnapshotComponent = ({position}: {position?: Point}) => {
  const mapRef = useRef<MapSnapshot | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  
  useEffect(() => {
    if (mapRef.current || mapContainerRef.current == null) return;

    mapRef.current = new MapSnapshot(mapContainerRef.current, position);
  });

  return (
    <div ref={mapContainerRef} />
  )
}

export default MapSnapshotComponent