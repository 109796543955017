import pRetry from 'p-retry';

const responseStatusArray = [504, 401]

export interface RestError {
    status: number,
    body: unknown
}

export type ValitationParams = Record<string, unknown>;

export interface ValidationError extends RestError {
    body: {
        errorCode: string,
        params?: ValitationParams
    }
}

const getRetryAttempts = ():number => {
    const defaultRetryAmount = 0;
    const retryString = process.env.REACT_APP_RETRY_AMOUNT;
    if (retryString) {
        return parseInt(retryString) || defaultRetryAmount;
    }
    return defaultRetryAmount;
}

const checkRetryStatus = (response: Response, retryErrorHandler: () => Promise<void>) => {
    if (!response.ok) {
        if (responseStatusArray.includes(response.status)) {
            return retryErrorHandler();
        } else {
            return errorHandler(response, (message: string) => new pRetry.AbortError(message))
        }
    }
}

export const errorHandler = async (response: Response, errorFactory: (message: string) => Error) => {
    const text = await response.text()
    const errorContent = {
        status: response.status,
        body: text.length !== 0 ? JSON.parse(text) : null
    } as RestError
    throw errorFactory(JSON.stringify(errorContent))
}

export { getRetryAttempts, checkRetryStatus};
