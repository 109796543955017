import {ApplicationState, goBack} from '../../../store/ApplicationSlice';
import {useAppSelector} from '../../../store/ReduxStore';
import MapSearchTile from '../../map/search/MapSearchTile'
import SearchArea from '../../sections/search/SearchArea';
import GoBack from '../navigation/GoBack';
import TimePicker from "../../sections/timePicker/TimePicker";
import SummaryArea from '../../sections/summary/SummaryArea';
import { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import VideoBox from "../../sections/search/videoBox/VideoBox";

const LeftPanel = () => {
  const { applicationState, leftPanelDisabled } = useAppSelector((state) => state.applicationState);
  const leftPanelHidden = applicationState === ApplicationState.LEFT_PANEL_HIDDEN;
  const showDisabledLayer = (applicationState === ApplicationState.SEND_ORDER_SCREEN || leftPanelDisabled);
  const dispatch = useDispatch();

  const style:CSSProperties = {
    width: 380,
    minWidth: 380,
    overflowY: 'auto',
    padding: 24
  }
  if (leftPanelHidden) {
    style.position = 'absolute';
    style.zIndex = 1;
  } else {
    style.position = 'relative';
    style.zIndex = 0;
  }

  return (
    <div style={style}>
      <MapSearchTile applicationState={applicationState} />
      {
        !leftPanelHidden &&
        <>
          {
            showDisabledLayer && <div className='disabledLayer' style={{left:0, top: 0}} onClick={() => dispatch(goBack())} />
          }
          <GoBack />
          <VideoBox applicationState={applicationState} />
          <SearchArea applicationState={applicationState} />
          <TimePicker applicationState={applicationState} />
          <SummaryArea applicationState={applicationState} />
        </>
      }
    </div>
  )
}

export default LeftPanel