import { User } from "oidc-client-ts";
import {authHeader as authHeaderWithParam} from "./api";
import { getOIDCAuthority, getOIDCClientId } from "../utils/env";

function getUser() {
    const oidcStorage = localStorage.getItem(`oidc.user:${getOIDCAuthority()}:${getOIDCClientId()}`)
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

export const authHeader = () => {
    return authHeaderWithParam(getUser()?.access_token);
}