import classes from "./SimCardSelector.module.css";
import {AppDispatch, getAvailabilitySelector, RootState, useAppSelector} from "../../../store/ReduxStore";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {ScaleDropdownSelect, ScaleDropdownSelectItem} from "@telekom/scale-components-react";
import {getSelectedSimCardsGivenRelatedParty, setRelatedParty, setSimCardIccId} from "../../../store/workflow/SimCardsSlice";
import {ScaleDropdownSelectCustomEvent} from "@telekom/scale-components";
import {checkAvailability} from "../../../api/backend/availabilityCheckApi";
import ActionResult from "../../common/ActionResult";
import {AvailabilityCheckRequest, SimCardDTO} from "../../../store/workflow/models";
import {goNext} from "../../../store/ApplicationSlice";

const SimCardSelector = ({showNextButton}: { showNextButton: boolean }) => {
    const { simCardRelatedPartiesList } = useAppSelector((state) => state.simCardsReducer);
    const simCardsList = useSelector<RootState, SimCardDTO[] | undefined>(getSelectedSimCardsGivenRelatedParty);
    const [showButton, setShowButton] = useState(false);
    const [selectId, setSelectId] = useState<string | undefined>('');
    const dispatch = useDispatch<AppDispatch>();
    const availabilityCheckRequest = useSelector<RootState, AvailabilityCheckRequest>(getAvailabilitySelector);
    const [imsiAvailable, setImsiAvailable] = useState<boolean>(true)
    const [isNonReachableService, setIsNonReachableService] = useState<boolean>(false)
    const [isRelatedPartyClicked, setIsRelatedPartyClicked] = useState<boolean>(false)

    useEffect(() => {
        dispatch(setSimCardIccId(selectId));
    }, [selectId, dispatch]);

    const handleChangeSelector = (event: ScaleDropdownSelectCustomEvent<unknown>) => {
        const returnValue = event.detail as {
            value: string
        }
        if (event.detail && returnValue.value) {
            setShowButton(true);
            setSelectId(returnValue.value);
        } else {
            setShowButton(false);
        }
    };

    const handleClickButton = () => {
        checkAvailability(availabilityCheckRequest)
            .then(res => {
                setImsiAvailable(res.imsiAvailable)
                if (res.imsiAvailable) {
                    dispatch(goNext());
                }
            })
            .catch(() => {
                    console.error("Availability check error");
                    setIsNonReachableService(true);
                }
            );
    }

    const showSelectSimCards = () => setIsRelatedPartyClicked(false)
    const toggleSelectSimCards = () => setIsRelatedPartyClicked(prev => !prev)
    const changeRelatedPartyHandler = (event: ScaleDropdownSelectCustomEvent<unknown>) => {
        const returnValue = event.detail as {
            value: string
        }
        if (event.detail && returnValue.value) {
            dispatch(setRelatedParty(returnValue.value));
            clearSimCardList();
        }
        showSelectSimCards();
    }

    const clearSimCardList = () => {
        setShowButton(false);
        setSelectId(undefined);
        setImsiAvailable(true);
        setIsNonReachableService(false);
        setIsRelatedPartyClicked(false);
    }

    return (
        <div className={`${classes.container}${isRelatedPartyClicked ? " " + classes.hide : ""}`}>
            {
                simCardRelatedPartiesList.length > 0 ?
                <>
                    <span className='eyebrow teleNeo16'>Rahmenvertrag</span>
                    <ScaleDropdownSelect
                        className={`${classes.selector} ${classes.relatedParties}`}
                        label="Rahmenvertrag auswählen"
                        onBlur={showSelectSimCards}
                        onScale-change={changeRelatedPartyHandler}
                        onClick={toggleSelectSimCards}
                    >
                        { simCardRelatedPartiesList.map((e, key) => {
                            return <ScaleDropdownSelectItem key={key} value={e.customerNr} selected={false} >{e.name}</ScaleDropdownSelectItem>;
                        })}
                    </ScaleDropdownSelect>
                </> : null
            }
            {
                simCardsList !== undefined ?
                <>
                    <span className='eyebrow teleNeo16 mt-2'>SIM-Karte</span>
                    <ScaleDropdownSelect
                        className={`${classes.selector} ${classes.simCards}`}
                        label="SIM-Karte auswählen"
                        onScale-change={handleChangeSelector}
                        value={selectId}
                        hideLabelVisually={selectId !== undefined}
                    >
                        { simCardsList.map((e, key) => {
                            return <ScaleDropdownSelectItem key={key} value={e.iccid} selected={false} >
                                <>
                                    <div>{e.alias}</div>
                                    <div>{e.iccid}</div>
                                    <div style={{fontSize: 10, color: "#ccc"}}>{e.imsi}</div>
                                </>
                                </ScaleDropdownSelectItem>;
                        })}
                    </ScaleDropdownSelect>
                </>
                : null
            }
            {
                showNextButton && showButton &&
                <button type="submit" className={`${classes.confirmationBtn} btn telekom-button-wide`} onClick={handleClickButton}>Eingaben bestätigen</button>
            }
            {
                !imsiAvailable &&
                <ActionResult isSuccess={false}>
                    Für diese SIM-Karte existiert eine bestehende Buchung, die sich mit dem gewünschten Buchungszeitraum überschneidet. Der Buchungszeitraum der neuen Buchung muss angepasst werden!
                </ActionResult>
            }
            {
                isNonReachableService &&
                <ActionResult isSuccess={false}>
                    Service nicht verfügbar. Bitte versuchen<br/>Sie es zu einem späteren Zeitpunkt erneut.
                </ActionResult>
            }
        </div>
    )
}

export default SimCardSelector