import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Provider } from 'react-redux';
import { store } from './store/ReduxStore';
import '@telekom/scale-components/dist/scale-components/scale-components.css';
import './i18n';
import {
    applyPolyfills,
    defineCustomElements,
} from '@telekom/scale-components/loader';
import Notification from "./components/ui/common/message/Notification";
import App from "./App";
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore, Log, OidcClient } from 'oidc-client-ts';
import { getOIDCAuthority, getOIDCClientId, getOIDCRedirectUrl } from './utils/env';

const el = document.getElementById('root')
if (el === null) throw new Error('Root container missing in index.html')

applyPolyfills().then(() => {
    defineCustomElements(window);
});

const oidcConfig: AuthProviderProps = {
    authority: getOIDCAuthority(),
    client_id: getOIDCClientId(),
    redirect_uri: getOIDCRedirectUrl(),
    userStore: new WebStorageStateStore({store: window.localStorage}),
    iframeScriptOrigin: "window.location.origin"
};
Log.setLogger(console);
Log.setLevel(Log.DEBUG);
new OidcClient(oidcConfig).createSigninRequest(oidcConfig).then(
    signingRequest => {
        console.log(`------------------ resp: ${JSON.stringify(signingRequest)}`)
        console.log(`oidcConfig: ${JSON.stringify(oidcConfig)}`)
    }
);

const root = ReactDOM.createRoot(el)
root.render(
    <React.StrictMode>
        <AuthProvider {...oidcConfig}>
            <Provider store={store}>
                <App/>
                <Notification/>
            </Provider>
        </AuthProvider>
    </React.StrictMode>
);
