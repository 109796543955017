import BookingWorkflowPage from "./pages/booking/BookingWorkflowPage";
import LoginPage from "./pages/login/LoginPage";
import {AppDispatch, useAppSelector} from "./store/ReduxStore";
import ProductSelectionPage from "./pages/product/ProductSelectionPage";
import MainBody from "./components/ui/layout/MainBody";
import DashboardPage from "./pages/dashboard/DashboardPage";
import { useAuth } from "react-oidc-context";
import { useEffect } from "react";
import { getProductList } from "./api/backend/productApi";
import { setProductList } from "./store/workflow/ProductSlice";
import { getCustomers } from "./api/backend/simCardsApi";
import { setSimCardsListRelatedParties } from "./store/workflow/SimCardsSlice";
import { useDispatch } from "react-redux";

function App() {
    const auth = useAuth();
    const dispatch = useDispatch<AppDispatch>();
    const applicationState = useAppSelector((state) => state.applicationState.applicationState);
    const {productList} = useAppSelector((state) => state.productReducer);
    const {simCardRelatedPartiesList} = useAppSelector((state) => state.simCardsReducer);

    useEffect(() => {
        if (auth.isAuthenticated && productList.length === 0) {
            getProductAction();
        }           
    }, [auth.isAuthenticated, productList.length]);
    useEffect(() => {
        if (auth.isAuthenticated && simCardRelatedPartiesList.length === 0) {
            getSimCardsRelatedPartiesAction()
        }           
    }, [auth.isAuthenticated, simCardRelatedPartiesList.length]);

    const getProductAction = async () => {
        try {
            const products = await getProductList();
            dispatch(setProductList(products));
        } catch (e) {
            console.error("Error in reading products");
        }
    }

    const getSimCardsRelatedPartiesAction = async () => {
        try {
            const customers = await getCustomers();
            dispatch(setSimCardsListRelatedParties(customers));
        } catch (e) {
            console.error("Error in reading simcards");
        }
    }

    return <MainBody>
            {auth.isAuthenticated ?
                        <>
                            <DashboardPage applicationState={applicationState} />
                            <ProductSelectionPage applicationState={applicationState} />
                            <BookingWorkflowPage applicationState={applicationState} />
                        </>
                :
                    <LoginPage />
            }
        </MainBody>
}

export default App;
