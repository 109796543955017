import { useDispatch } from "react-redux";
import { backArrow } from "../../../assets/Consts"
import { goBack } from "../../../store/ApplicationSlice"
import { useAppSelector } from "../../../store/ReduxStore";
import { GeocodeAddress } from "../../../store/workflow/MapSlice";
import classes from "./GoBack.module.css"

const GoBack = () => {
  const dispatch = useDispatch();
  const { geocodeAddress } = useAppSelector((state) => state.mapReducer);

  return (
    <div className={`${classes.container} teleNeo32Bold`} onClick={() => dispatch(goBack())}>
        <div className={classes.backArrow}><img src={ backArrow } alt="<" /></div>
        <div className={classes.placeName}>{getBackText(geocodeAddress)}</div>
    </div>
  )
}

export const getBackText = (address?: GeocodeAddress) => (address?.city && address.road) ? <>{address.road},<br/>{address.city}</> : "Zurück"

export default GoBack