import {telekomLogo, userLogo} from "../../../assets/Consts"
import HeaderLine from "./HeaderLine"
import UserInfromationBox from "./UserInformationBox";
import SessionTimer from "./SessionTimer";
import { useAuth } from "react-oidc-context";

const Header = () => {
    const auth = useAuth();

    return (
    <>
        <HeaderLine style={{background: "#E20074", color: "white"}}>
            <img src={telekomLogo} alt="Telekom logo"/>
            <div className="text-header justify-content-start">
                <div>Erleben,</div>
                <div>was verbindet.</div>
            </div>
        </HeaderLine>
        <HeaderLine style={{background: "white", height: 60}} className="second-header">
            <div>
                <h5 className="title-text">Live Video Production</h5>
            </div>
            { auth.isAuthenticated &&
                <div className="dropstart">
                    <div>
                        <div className="dropdown">
                            <button className="btn-primary-outline dropdown-toggle user-button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={userLogo} className="user-icon" alt="user" />{auth.user?.profile.name}
                            </button>
                            <ul className="dropdown-menu user-info-box dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                <UserInfromationBox />
                            </ul>
                        </div>
                    </div>
                    <SessionTimer></SessionTimer>
                </div>
            }
        </HeaderLine>
    </>
  )
}

export default Header