import { GeocodeAddress } from "../../store/workflow/MapSlice";
import { DateOrder, Order } from "../../store/workflow/models";
import { PropsReactChildren } from "../common/model";
import AddressDetails from "../sections/sendOrder/AddressDetails";
import {formatDate, formatMinutes, formatSimCardNumber} from "../utils/OrderDetailsFormatter";

const OrderDetails = ({order, padding, address} : {order: Order, padding: number, address?: GeocodeAddress}) => {
  const orderDate:DateOrder = order.dateOrder ?? { dateFrom: "", dateTo: "" };

  return (
    <>
        <SendOrderLine title="Anfangsdatum" padding={padding}>
            {formatDate(orderDate.dateFrom ?? "")}, {orderDate.hourFrom}:{formatMinutes(orderDate.minuteFrom ?? 0)}
        </SendOrderLine>
        <SendOrderLine title="Enddatum" padding={padding}>
            {formatDate(orderDate.dateTo ?? "")}, {orderDate.hourTo}:{formatMinutes(orderDate.minuteTo ?? 0)}
        </SendOrderLine>
        {/*TO-DO make calcultion of amount of units*/}
        {/*<SendOrderLine title="Amounts of units" padding={padding}>*/}
        {/*    {formatHours(orderDate)}*/}
        {/*</SendOrderLine>*/}
        <SendOrderLine title="SIM-Karte" padding={padding}>
            {formatSimCardNumber(order.simCard?.iccid ?? "")}
        </SendOrderLine>
        <SendOrderLine title="Adresse" padding={padding}>
            <AddressDetails order={order} address={address} />
        </SendOrderLine>
    </>
  )
}

const SendOrderLine = ({title, children, padding} : {title: string, children: PropsReactChildren, padding: number}) => {
  return (
      <div style={{paddingTop: padding}}>
          <div className='teleNeo16BoldLight' style={{marginBottom: 4}}>
              {title}:
          </div>
          <div className='teleNeo16Light'>
              {children}
          </div>
      </div>
  )
}

export default OrderDetails