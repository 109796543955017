import mapboxgl from "mapbox-gl";
import { Point } from "../../../store/workflow/models";

export abstract class MapBase {
    private readonly _map;
    private readonly handlers;

    protected constructor(container: HTMLElement, initPosition?: Point) {
        this._map = new mapboxgl.Map({
            container: container,
            style: '/osm_telekom_light.json',
            center: initPosition,
            zoom: 15,
            dragRotate: false,
            accessToken: process.env.REACT_APP_MAP_ACCESS_TOKEN
        });

        this.handlers = [
          this._map.boxZoom,
          this._map.doubleClickZoom,
          this._map.dragPan,
          this._map.keyboard,
          this._map.touchZoomRotate,
          this._map.scrollZoom
        ]
    }

    get map () {
        return this._map;
    }

    setEnabled(enable: boolean) {
      if (!enable) {
        this.handlers.forEach(handler => handler.disable())
      } else {
        this.handlers.forEach(handler => handler.enable())
      }
    }
}