import ModalTemplate from '../../components/ui/common/ModalTemplate'
import classes from "./CancelOrderConfirmationModal.module.css";
import {ScaleLoadingSpinner} from "@telekom/scale-components-react";

const CancelOrderConfirmationModal = ({confirmHandler, closeHandler, isCancelling, errorStatus}: {confirmHandler: () => void, closeHandler: () => void, isCancelling: boolean, errorStatus: number | undefined}) => {
  return (
    <>
        <div className='disabledLayer' style={{left:0, top: 0}} />
        <ModalTemplate className={classes.cancelOrder}>
            {
                errorStatus ?
                    <div>
                        <div style={{textAlign: "center"}}>
                            Fehler beim Stornieren aufgetreten ({errorStatus})
                        </div>
                        <div className={classes.buttonPanel}>
                            <button className="telekom-button-magenta telekom-button-fat btn" onClick={closeHandler}>zum
                                Dashboard
                            </button>
                        </div>
                    </div> :
                !isCancelling ? <>
                    Wollen Sie diese Buchung wirklich stornieren?
                    <div className={classes.buttonPanel}>
                        <button className="telekom-button-magenta telekom-button-fat btn" onClick={confirmHandler}>Ja</button>
                        <button className="telekom-button-magenta telekom-button-fat btn" onClick={closeHandler}>Nein</button>
                    </div>
                </> :
                    <div style={{textAlign: "center"}}>
                        <div>
                            Die Buchung wird storniert...
                        </div>
                        <div style={{textAlign: "center"}}>
                            <ScaleLoadingSpinner/>
                        </div>
                    </div>

            }

        </ModalTemplate>
    </>
  )
}

export default CancelOrderConfirmationModal