import classes from "./EditableProductNameBox.module.css";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../store/ReduxStore";
import React, {useRef, useState} from "react";
import {ScaleButton} from "@telekom/scale-components-react";
import {setNewNameOfProduct} from "../../../../store/workflow/ProductSlice";
import {useTranslation} from "react-i18next";

const EditableProductNameBox = ({setShowEditField} : {setShowEditField: React.Dispatch<React.SetStateAction<boolean>>}) => {
    const productNameFieldRef = useRef<HTMLInputElement>(null);
    const MAX_DESCRIPTION_LENGTH = 40;
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [error, setError] = useState<string | undefined | null>()

    const saveProductName = () => {
        if (productNameFieldRef.current === null) {
            return;
        }
        const currentName = productNameFieldRef.current?.value;
        if (currentName.length > MAX_DESCRIPTION_LENGTH) {
            setError(t("order.description.maxlength"))
        } else {
            dispatch(setNewNameOfProduct(currentName));
            setShowEditField(false);
            setError(undefined)
        }
    }

    const onKeyDownHandler = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.code === "NumpadEnter") {
            saveProductName();
        }
    }

    return (
        <div className={classes.contentDiv}>
            {
                error !== undefined ?
                <div className="alert alert-danger" role="alert">
                    {error}
                </div> : null
            }
            <input type="text" ref={productNameFieldRef} className={classes.inputText} placeholder="Name editieren" onKeyDown={onKeyDownHandler} autoFocus/>

            <div className={classes.divWithButtons}>
                <ScaleButton size="small" className={classes.btn} variant="secondary" onClick={() => setShowEditField(false)}>Abbrechen</ScaleButton>
                <ScaleButton size="small" className={classes.btn} onClick={() => saveProductName()}>Bestätigen</ScaleButton>
            </div>
        </div>
    )
}

export default EditableProductNameBox