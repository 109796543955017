import { ScaleDivider } from "@telekom/scale-components-react"
import { CSSProperties } from "react"

const HorizontalLine = () => {
  const lineStyle:CSSProperties = {
    marginBottom: 16,
    marginTop: 16
  }

  return (
    <div style={lineStyle}>
      <ScaleDivider />
    </div>
    
  )
}

export default HorizontalLine