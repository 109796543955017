import { useAppSelector } from "../../../store/ReduxStore";
import TileTemplate from "../../ui/common/TileTemplate";
import MapHistory from "./MapHistory"
import MapSuggestions from "./MapSuggestions";
import classes from "./MapSearchTile.module.css"
import './MapSearchInputBox.css';
import { ApplicationState } from "../../../store/ApplicationSlice";

const MapSearchTile = ({applicationState} : {applicationState: ApplicationState}) => {
  const hideHistory = useAppSelector((state) => state.mapReducer.hideHistory);
  const leftPanelHidden = applicationState === ApplicationState.LEFT_PANEL_HIDDEN;
  const showMapSearchTile = [ApplicationState.LEFT_PANEL_HIDDEN, ApplicationState.SEARCH_SCREEN].includes(applicationState) ;
  return (
    <TileTemplate className={`${classes.mapSearchTile} ${leftPanelHidden ? "fullPageMap" : "noTileLayout"}`} style={{display: showMapSearchTile ? "block" : "none"}}>
      <div id="mapSearchInputBox"></div>
        {
           leftPanelHidden &&
            (hideHistory ? <MapSuggestions /> : <MapHistory />)
        }
    </TileTemplate>
  )
}

export default MapSearchTile