import mapboxgl from "mapbox-gl";
import { Point } from "../../../store/workflow/models";
import { MapBase } from "./MapBase";

export class MapSnapshot extends MapBase {
    constructor(container: HTMLElement, initPosition?: Point) {
        super(container, initPosition);

        this.setEnabled(false);
        new mapboxgl.Marker()
            .setLngLat(initPosition ?? {
                lat: 0,
                lng: 0
            })
            .addTo(this.map);
    }
}