import { useDispatch } from "react-redux";
import { setSearchQuery } from "../../../store/workflow/MapSlice";
import { useAppSelector } from "../../../store/ReduxStore";
import classes from "./MapHistory.module.css"

const MapHistoryTile = () => {
  const dispatch = useDispatch();
  const historyEntries = useAppSelector((state) => state.mapReducer.searchHistory);

  return (
    <div className={`${classes.container}`}>
      <div className={`${classes.title}`}>Letzte Suchen</div>
      <ul className={`${classes.list}`}>
        {
          historyEntries?.map((entry, index) =>
            <li key={index} className="teleNeo16" onClick={() => dispatch(setSearchQuery(entry))}><div>{entry}</div></li>
          )
        }
      </ul>
    </div>
  )
}

export default MapHistoryTile