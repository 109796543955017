import { IControl, Map, Marker } from "mapbox-gl";

import { renderToStaticMarkup } from "react-dom/server";

const MapCenterButtonComponent = () => {
  return (
    <button className="mapboxgl-ctrl-center" type="button" aria-label="Center" aria-disabled="false">
      <span className="mapboxgl-ctrl-icon" aria-hidden="true" title="Center"></span>
    </button>
  )
}

class MapCenterButton implements IControl {
    private readonly _divElement;
    private readonly positionMarker;
    constructor (marker: Marker) {
      this._divElement = document.createElement("div");
      this._divElement.setAttribute("class", "mapboxgl-ctrl mapboxgl-ctrl-group center")
      this.positionMarker = marker;
    }
  
    onAdd(map: Map): HTMLElement {
      const staticElement = renderToStaticMarkup(<MapCenterButtonComponent />);
      this._divElement.innerHTML = `${staticElement}`;
      this._divElement.addEventListener('click', () => {
        map.flyTo({
            center: this.positionMarker.getLngLat()
        });
      });
      return this._divElement;
    }
    onRemove(): void {
      this._divElement.remove();
    }
    getDefaultPosition?: (() => string) | undefined;
  }

  
  export default MapCenterButton