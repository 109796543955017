import { isoDuration,de } from "@musement/iso-duration";
import { ValitationParams } from "../api/retryUtils";

const humanizedProperties = ["minLeadTime", "maxLeadTime", "bookingPeriod"]
isoDuration.setLocales({ de });

export const humanizeDurationIso8601 = (params?: ValitationParams): object => {
    if (!params) return {}

    const updatedParams = { ...params };

    Object.keys(updatedParams).forEach((key) => {
        if (humanizedProperties.includes(key)) {
            updatedParams[key] = isoDuration(params[key] as string).humanize("de");
        }
    });

    return updatedParams;
}