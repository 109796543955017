import {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { setPositionAndAddress } from '../store/workflow/MapSlice';
import { Point } from '../store/workflow/models';
import { AppDispatch, useAppSelector } from '../store/ReduxStore';

function useGetGeolocation() {
    const position = useAppSelector(state => state.mapReducer.position)
    const [geoPosition, setGeoPosition] = useState<Point>();

    useEffect(() => {
        if (position === undefined) {
            navigator.geolocation.getCurrentPosition((geolocationPosition) => {
                setGeoPosition({
                    lat: geolocationPosition.coords.latitude,
                    lng: geolocationPosition.coords.longitude,
                });
            }, positionError => {
               console.error('positionError', positionError);
               setGeoPosition({lat: 0, lng: 0});
            });
        }
    }, [position]);

    return geoPosition;
}

export const useCurrentOrSavedPosition = () => {
    const dispatch = useDispatch<AppDispatch>();
    const position = useAppSelector(state => state.mapReducer.position)

    const geoPosition = useGetGeolocation();

    useEffect(() => {
        if (position === undefined && geoPosition !== undefined) {
            dispatch(setPositionAndAddress(geoPosition))
        }
    }, [position, geoPosition, dispatch]);

    return position;
};
