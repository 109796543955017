import DatePicker from "./elements/DatePicker";
import HourPicker from "./elements/HourPicker";
import classes from "./TimePicker.module.css";
import {ApplicationState} from "../../../store/ApplicationSlice";
import SimCardSelector from "../simCardSelector/SimCardSelector";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDate, setTime} from "../../../store/workflow/DateSlice";
import {RootState} from "../../../store/ReduxStore";
import {ProductDTO} from "../../../store/workflow/models";
import {getSelectedProductSelector} from "../../../store/workflow/ProductSlice";
import moment from "moment";
import {useNotification} from "../../../hooks/useNotification";

const TimePicker = ({applicationState}: { applicationState: ApplicationState }) => {
    const dispatch = useDispatch();
    const showSearchPanel = applicationState === ApplicationState.TIME_AND_DATE_CARD_SCREEN;
    const [showNextButton, setShowNextButton] = useState<boolean>(true);
    const { displayNotification, clearNotification} = useNotification();
    const selectedProduct = useSelector<RootState, ProductDTO | undefined>(getSelectedProductSelector);
    const maxDuration = moment.duration(selectedProduct?.maxDuration?.value).asSeconds();
    const date = new Date();
    const [timeFrom, setTimeFrom] = useState(getTime2Digits(date.getHours()) + ":" + getTime2Digits(date.getMinutes()));
    const [timeTo, setTimeTo] = useState(getTime2Digits(date.getHours()) + ":" + getTime2Digits(addMinutesToDate(date, 1).getMinutes()));
    const [dateFrom, setDateFrom] = useState<string>("");
    const [dateTo, setDateTo] = useState<string>("");
    const [invalidTime, setInvalidTime] = useState(false);
    const [invalidDate, setInvalidDate] = useState(false);

    const checkMaxDuration = () => {
        if (showNextButton) {
            const dayTimeFrom = moment(dateFrom + ' ' + timeFrom);
            const dayTimeTo = moment(dateTo + ' ' + timeTo);

            dayTimeTo.diff(dayTimeFrom, 'seconds') > maxDuration ?
                displayNotification({
                    open: true,
                    cssType: "alert-warning",
                    message: "Achtung, angefragter Zeitslot ist sehr lang."})
                : clearNotification();
        }
    }

    const checkTime = () => {
        if (timeFrom && timeTo) {
            const dayTimeFrom = moment(dateFrom + ' ' + timeFrom);
            const dayTimeTo = moment(dateTo + ' ' + timeTo);
            if (dayTimeFrom >= dayTimeTo) {
                setInvalidTime(true);
                setShowNextButton(false);
            } else {
                setInvalidTime(false);
                dispatch(setTime({
                    hourFrom: Number(timeFrom.split(':')[0]),
                    minuteFrom: Number(timeFrom.split(':')[1]),
                    hourTo: Number(timeTo.split(':')[0]),
                    minuteTo: Number(timeTo.split(':')[1])
                }));
                setShowNextButton(true);
            }
        }
    }

    useEffect(() => {
        const newTimeTo = moment(timeFrom, 'HH:mm').add(1, 'minutes').format('HH:mm');
        setTimeTo(newTimeTo);
    }, [timeFrom]);

    const checkDate = () => {
        if (dateFrom && dateTo) {
            if (new Date(dateFrom) > new Date(dateTo)){
                setInvalidDate(true);
                setShowNextButton(false);
            } else {
                setInvalidDate(false);
                dispatch(setDate({
                    dateFrom: dateFrom,
                    dateTo: dateTo}));
                setShowNextButton(true);
            }
        }
    }

    useEffect( ()=> {
        checkTime();
        checkDate();
        checkMaxDuration();

    }, [timeFrom, timeTo, dateFrom, dateTo, dispatch, setShowNextButton]);

    return showSearchPanel ?
        <div>
            <div className={classes.container}>
                <div className={classes.dateAndTimeContainer}>
                    <DatePicker spanLabel="Anfangsdatum" dateValue={dateFrom} setSelectedDate={setDateFrom} invalid={invalidDate}/>
                    <HourPicker spanValue="Anfangszeit" label="Von" timeValue={timeFrom} setSelectedTime={setTimeFrom} invalid={invalidTime}/>
                </div>

                <div className={classes.dateAndTimeContainer}>
                    <DatePicker spanLabel="Enddatum" dateValue={dateTo} setSelectedDate={setDateTo} invalid={invalidDate}/>
                    <HourPicker spanValue="Endzeit" label="Bis" timeValue={timeTo} setSelectedTime={setTimeTo} invalid={invalidTime}/>
                </div>
            </div>
            <SimCardSelector showNextButton={showNextButton}/>
        </div>: null
}

function addMinutesToDate(date: Date, minutes: number): Date {
    return new Date(new Date(date).setMinutes(date.getMinutes() + minutes));
}

function getTime2Digits(time: number) {
    return time <= 9 ? '0' + time : time;
}

export default TimePicker