import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { resetWorkflow } from '../CommonActions';
import { DateOrder } from './models';

const initialState:DateOrder = {
    dateFrom: new Date().toISOString(),
    dateTo: new Date().toISOString(),
    hourFrom: 0,
    hourTo: 0,
    minuteFrom: 0,
    minuteTo: 0,
    zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
}

const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    setDate(state, action: PayloadAction<{dateFrom: string, dateTo: string}>) {
        state.dateFrom = action.payload.dateFrom;
        state.dateTo = action.payload.dateTo;
    },
    setTime(state, action: PayloadAction<{hourFrom:number, minuteFrom:number, hourTo:number, minuteTo: number}>){
          state.hourFrom = action.payload.hourFrom;
          state.minuteFrom = action.payload.minuteFrom;

          state.hourTo = action.payload.hourTo;
          state.minuteTo = action.payload.minuteTo;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetWorkflow, () => initialState)
  },
})

export const { setDate, setTime} = dateSlice.actions
export default dateSlice.reducer