import Header from "../header/Header";
import Breadcrumb from "../navigation/Breadcrumb";
import Footer from "../Footer";
import { PropsReactChildren } from "../../common/model";

const MainBody = ({children} : {children: PropsReactChildren}) => {
    return (
        <>
            <Header />
            <Breadcrumb />
            <div className={`container-fluid ps-0 pe-0 bread mainContainer`}>
                <div style={{width: "100%"}}>
                    {children}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MainBody
