import { useDispatch } from 'react-redux';
import { goNext } from '../../../store/ApplicationSlice';
import { ProductDTO } from '../../../store/workflow/models';
import TileTemplate from '../../ui/common/TileTemplate'
import { formatMoney } from '../../utils/OrderDetailsFormatter';
import classes from "./PriceTile.module.css";
import moment from "moment-timezone";

const PriceTile = ({selectedProduct} : {selectedProduct: ProductDTO | undefined}) => {
  const price = selectedProduct?.price !== undefined ? selectedProduct.price : 0;
    const lengthBillingUnit = selectedProduct?.lengthBillingUnit !== undefined ? moment.duration(selectedProduct?.lengthBillingUnit.value).asMinutes() : 0;
  const dispatch = useDispatch();

  return (
    <TileTemplate className={`${classes.container}`}>
      <div className='teleNeo16 gray'>Preis</div>
      <div className={`magenta ${classes.price}`}>
        <span className={`${classes.left}`}>{formatMoney(price)} €</span>
        <span className={`${classes.right}`}> /{lengthBillingUnit} Min.</span>
        <button type="submit" className="btn telekom-button-wide" style={{marginTop: 16}} onClick={async () => dispatch(goNext())}>
          Buchung abschließen
        </button>
      </div>
    </TileTemplate>
  )
}

export default PriceTile