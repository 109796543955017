import mapboxgl, {Marker} from "mapbox-gl";
import {GeocoderWrapper} from "./GeocoderWrapper";
import {Dispatch} from 'redux';
import MapInfoTile from "../mapInfo/MapInfoTile";
import MapCenterButton from "../mapCenter/MapCenterButton";
import {IsPointAtLayer, Point} from "../../../store/workflow/models";
import {MapBase} from "./MapBase";
import { setLeftPanelDisabled } from "../../../store/ApplicationSlice";
import circle from "@turf/circle";
import debounce from "lodash/debounce";
import { fetchIsAtLayer, fetchMapboxLayer, getMapBoxRequest } from "../../../api/backend/tmapApi";
import { setMapboxRequest } from "../../../store/workflow/MapSlice";

export class MapWrapper extends MapBase {
    private readonly geocoderWrapper;
    private readonly OVERLAY_SOURCE_ID = "overlaySource";
    private readonly marker;

    constructor(container: HTMLElement, initPosition: Point, dispatch: Dispatch, marker: Marker) {
        super(container, initPosition);
        this.marker = marker;
        this.geocoderWrapper = new GeocoderWrapper(dispatch, this, process.env.REACT_APP_MAP_ACCESS_TOKEN);

        this.map.addControl(new mapboxgl.NavigationControl(), "bottom-right");
        this.map.addControl(new MapCenterButton(marker), "bottom-right");
        document.getElementById('mapSearchInputBox')?.appendChild(this.geocoderWrapper.geocoder.onAdd(this.map));
        this.map.addControl(
            new MapInfoTile(),
            "bottom-left"
        );

        const debouncedEndHandler = debounce(async () => this.moveEndHandler(), 300);
        
        this.map.on('moveend', () => debouncedEndHandler())

        this.map.on('load', () => {
            this.updateCircle(true)
            this.map.fire('moveend')
        })
    }

    private async moveEndHandler() {
        const mapBounds = this.map.getBounds();
    
        this.hideSpinner(false);
        const blob = await fetchMapboxLayer(this.map)

        const reader = new FileReader();

        reader.onloadend = () => {
            if (this.map.getSource(this.OVERLAY_SOURCE_ID) != null) {
                this.map.removeLayer("overlayLayer");
                this.map.removeSource(this.OVERLAY_SOURCE_ID);
            }

            const base64data = reader.result;

            if (typeof base64data === "string")
            this.map.addSource(this.OVERLAY_SOURCE_ID, {
                type: 'image',
                url: base64data,
                coordinates: [latLngToArray(mapBounds.getNorthWest()), latLngToArray(mapBounds.getNorthEast()),
                latLngToArray(mapBounds.getSouthEast()), latLngToArray(mapBounds.getSouthWest())]
            })
            this.map.addLayer({
                id: "overlayLayer",
                source: this.OVERLAY_SOURCE_ID,
                type: "raster"
            });
            this.map.setPaintProperty(
                'overlayLayer',
                'raster-opacity',
                0.3
            );
            
            this.map.on('sourcedata', this.sourceCallback);
    
            this.map.moveLayer("overlayLayer", "road_label");
        }

        reader.readAsDataURL(blob)
    }

    sourceCallback = () => {
        if (this.map.getSource(this.OVERLAY_SOURCE_ID) && this.map.isSourceLoaded(this.OVERLAY_SOURCE_ID)) {
            this.hideSpinner(true);
        }
    }

    hideSpinner = (hide: boolean) => {
        const spinner = document.getElementById("spinnerDiv");

        if (spinner !== null) {
            spinner.style.display = hide ? 'none' : 'block';
        }
    }

    query = (query: string) => {
        this.geocoderWrapper.geocoder.query(query)
    }

    isPositionValid = async (point: Point, dispatch: Dispatch): Promise<boolean> => {
        dispatch(setLeftPanelDisabled(true));

        const layerInfo: IsPointAtLayer = await fetchIsAtLayer(this.map, point)
        dispatch(setLeftPanelDisabled(false));

        if (layerInfo.isAtLayer) {
            dispatch(setMapboxRequest(getMapBoxRequest(this.map, { point })))
        }
        
        return layerInfo.isAtLayer;
    }

    updateCircle = (isPositionValid: boolean) => {
        if (!this.map.isStyleLoaded()) {
            return;
        }
        const markerLngLat = this.marker.getLngLat();
        if (this.map.getSource('circle')) {
            this.map.removeLayer('circle');
            this.map.removeSource('circle');
        }
  
        if (!isPositionValid) {
            this.map.addSource('circle', {
                type: "geojson",
                data: circle([markerLngLat.lng, markerLngLat.lat], 500, {
                    steps: 50,
                    units: "meters"
                })
            });
      
            this.map.addLayer({
              id: 'circle',
              type: 'circle',
              source: 'circle',
              paint: {
                // Make circles larger as the user zooms from z12 to z22
                // The second array element is the dot size
                'circle-radius': {
                    'base': 1.75,
                    'stops': [
                        [12, 2],
                        [22, 120]
                    ]
                }
              }
            });
        }
      };
}

export const latLngToArray = (bounds: mapboxgl.LngLat) => {
    return [ bounds.lng, bounds.lat ];
}