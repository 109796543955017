import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import { resetWorkflow } from '../CommonActions';
import { ProductDTO } from './models';
import { RootState } from '../ReduxStore';

interface ProductContextType {
    productList: ProductDTO[];
    selectedProductIndex?: number;
    userProductName?: string;
}

export const initialState:ProductContextType = {
    productList: []
}

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductList(state, action: PayloadAction<ProductDTO[]>) {
        state.selectedProductIndex = undefined;
        state.userProductName = undefined;
        state.productList = action.payload;
    },
    selectProductIndex(state, action: PayloadAction<number>) {
        state.selectedProductIndex = action.payload;
        state.userProductName = getSelectedProduct(state)?.name;
    },
    setNewNameOfProduct(state, action: PayloadAction<string>) {
        state.userProductName = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(resetWorkflow, (state) => {
      state.userProductName = undefined;
    })
  },
})

const getSelectedProduct = (state: ProductContextType) => {
  if (state.selectedProductIndex !== undefined && !isNaN(state.selectedProductIndex)) {
    return state.productList[state.selectedProductIndex];
  }
  return undefined;
}

export const getSelectedProductSelector = createSelector(
    (rootState: RootState) => rootState.productReducer,
    (productState) => getSelectedProduct(productState)
)

export const { setProductList, selectProductIndex, setNewNameOfProduct } = productSlice.actions
export default productSlice.reducer