import {ScaleButton} from "@telekom/scale-components-react";
import classes from "./ProductSelectionPage.module.css";
import {mapImage} from "../../assets/Consts";
import ProductCard from "../../components/product/ProductCard";
import {ApplicationState} from "../../store/ApplicationSlice";
import {goToDashboard} from "../../store/ApplicationSliceActions";
import {useDispatch} from "react-redux";
import {AppDispatch, useAppSelector} from "../../store/ReduxStore";

const ProductSelectionPage  = ({applicationState}: { applicationState: ApplicationState }) => {
    const dispatch = useDispatch<AppDispatch>();
    const showProductSelectionPage = applicationState === ApplicationState.PRODUCT_SELECTION_SCREEN;
    const {productList} = useAppSelector((state) => state.productReducer);

    return  showProductSelectionPage ?
        <>
            <div className={`${classes.container}`}>
                <div className={`${classes.label}`}>Verbindungen mit 5G<br/> Live Video Broadcast</div>
                <div className={classes.buttonDiv}>
                    <ScaleButton href="https://yam-united.telekom.com/workspaces/network-slicing/apps/content/home" target="_blank" variant="secondary" className={`${classes.button}`}>Weitere Infos</ScaleButton>
                    <ScaleButton onClick={() => dispatch(goToDashboard())} className={`${classes.button}`}>Zum Dashboard</ScaleButton>
                </div>

                <div className={`${classes.imageContainer}`} style={{backgroundImage: "url(" + mapImage + ")"}}>
                    <div className={`${classes.titleContainer}`}>
                        <div className={`teleNeo42 {classes.title}`}>Verfügbarkeit Ihrer Services</div>
                        <div className={`teleNeo16 {classes.subtitle}`}>Fragen Sie die Verfügbarkeit der Services an Ihrer<br/>Location auf der Karte ab.</div>
                    </div>
                </div>

                <div className={`${classes.textContainer}`}>
                    <div className={`teleNeo32Bold ${classes.biggerText}`}>Unsere beliebtesten Pakete</div>
                    <div className={`teleNeo16Light ${classes.smallerText}`}>Unsere beliebtesten Pakete bieten einen guten vorab konfigurierten Einstiegspunkt in <br/>unsere Services. Individuelle Anpassungen sind möglich.</div>
                </div>
                <div className={`${classes.cardSectionContainer}`}>
                    <div className={`${classes.cardsContainer}`}>
                        {
                            productList.map((prod, index) =>
                                <ProductCard key={index} product={prod}
                                                     nameCard={"Video"}
                                                     infoPoints={[
                                                         "Nutzung des Dienstes nur mit einer SIM-Karte [je Event] möglich",
                                                         "angestrebte Mindest-Upload-Rate beträgt 8 Mbit/s",
                                                         "angestrebte Mindest-Download-Rate beträgt 3 Mbit/s außerhalb von Gebäuden"
                                                     ]}
                                                     hidePrice={false}
                                                     index={index}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </> : null
}

export default ProductSelectionPage