import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  de: {
    translation: {
      "order.description.maxlength": "Der Text ist auf 40 Zeichen limitiert. Außerdem dürfen keine personenbezogenen Daten eingetragen werden!",
      "error.default": "Ein unerwarteter Fehler ist aufgetreten",
      "error.pom": "Die Order konnte nicht prozessiert werden!",
      "error.pom.minleadtime": "Der Startzeitpunkt der Buchung muss mindestens {{minLeadTime}} in der Zukunft liegen",
      "error.pom.maxleadtime": "Der Startzeitpunkt der Buchung kann maximal {{maxLeadTime}} liegen",
      "error.pom.bookingPeriod": "Die Buchung konnte nicht abgeschlossen werden. Die Buchungsdauer kann maximal [{{bookingPeriod}}] betragen"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "de",

    interpolation: {
      escapeValue: true
    }
  });

  export default i18n;