function assertDefined<T>(obj: T | undefined, message: string): asserts obj is T {
    if (obj === undefined) {
        throw new Error(message)
    }
}

const getDefinedEnvVariable = (key: string): string => {
    const env = process.env[key];
    assertDefined(env, `${key} variable is undefined`);
    return env;
}

export const getOIDCRedirectUrl = () => getDefinedEnvVariable("REACT_APP_OIDC_REDIRECT_URL")
export const getOIDCAuthority = () => getDefinedEnvVariable("REACT_APP_OIDC_AUTHORITY")
export const getOIDCClientId = () => getDefinedEnvVariable("REACT_APP_OIDC_CLIENT_ID")