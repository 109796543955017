import { IsPointAtLayer } from "../../store/workflow/models";
import * as api from "../api";
import {authHeader} from "../apiHeader";

const urlPreffix = "map"

const fetchMapboxLayerUrl = `${process.env.REACT_APP_BACKEND_API_URL}/${urlPreffix}/fetch-mapbox-layer`
const fetchIsAtLayerUrl = `${process.env.REACT_APP_BACKEND_API_URL}/${urlPreffix}/is-at-layer`

export const fetchMapboxLayer = (map: mapboxgl.Map) => callMapService<Blob>(fetchMapboxLayerUrl, map, undefined, res => res.blob());
export const fetchIsAtLayer = (map: mapboxgl.Map, point: {lat: number, lng: number}) => callMapService<IsPointAtLayer>(fetchIsAtLayerUrl, map, {
    point: {
        lng: point.lng.toFixed(15),
        lat: point.lat.toFixed(15)
    }
});

export type MapboxRequest = {
    mapSize: Size,
    sw: Point,
    ne: Point,
    point?: Point,
}

type Size = {
    width: number,
    height: number,
}

type Point = {
    lng: number,
    lat: number,
}

export const getMapBoxRequest = (map: mapboxgl.Map, additionalParams?: object): MapboxRequest => ({
    mapSize: {
        width: map.getCanvas().width,
        height: map.getCanvas().height,
    },
    sw: {
        lng: map.getBounds().getSouthWest().lng,
        lat: map.getBounds().getSouthWest().lat,
    },
    ne: {
        lng: map.getBounds().getNorthEast().lng,
        lat: map.getBounds().getNorthEast().lat,
    },
    ...additionalParams
})

const callMapService = <T,> (serviceUrl: string, map: mapboxgl.Map, additionalParams?: object, responseMapper?: ((res: Response) => Promise<T>)): Promise<T> => {
    return api.authorizedPost<T>(serviceUrl, authHeader(), getMapBoxRequest(map, additionalParams), responseMapper)
}