import moment from "moment-timezone";
import { DurationInputArg2 } from "moment";
import 'moment/locale/de'
import {UserOrder} from "../../store/workflow/models";


export const formatDate = (date: string): string => {
  return moment(date).locale('de').format("dd., DD.MM.YYYY");
}

export const formatDateAndTime = (date: string): string => {
  return moment(date).tz('Europe/Berlin').locale('de').format("dd., DD.MM.YYYY HH:mm:ss");
}

export const formatDuration = (durationString: string): string => {
  const duration = moment.duration(durationString).locale('de');
  const humanizedDuration: string[] = [];
  fillFormatDuration(humanizedDuration, Math.floor(duration.asDays()), "days")
  fillFormatDuration(humanizedDuration, duration.hours(), "hour")
  fillFormatDuration(humanizedDuration, duration.minutes(), "minute")
  return humanizedDuration.length ? humanizedDuration.reduce((sum, elem) => sum + ", " + elem) : "";
}

const fillFormatDuration = (formattedDuration: string[], value: number, unit: DurationInputArg2) => {
  if (value > 0) {
    formattedDuration.push(moment.duration(value, unit).humanize({d:9999, h: 24, m: 60}))
  }
}

export const dateAndDuration = (currentOrder: UserOrder) => {
  return <>{currentOrder.date ? formatDateAndTime(currentOrder.date) : ""}<br/>
    {currentOrder.duration ? formatDuration(currentOrder.duration) : ""}</>
}

export const formatHours = ({hourFrom, hourTo, minuteFrom, minuteTo} :
  {hourFrom?: number, hourTo?: number, minuteFrom?: number, minuteTo?: number}): string => {

  if (hourFrom === undefined || minuteFrom === undefined ||
    hourTo === undefined || minuteTo === undefined) {
    return '';
  }
  return `${hourFrom}:${minuteFrom} - ${hourTo}:${minuteTo} Uhr (${countMinutes(hourFrom, minuteFrom, hourTo, minuteTo)} Min.)`;
}

export const countMinutes = (hourFrom: number, minuteFrom: number, hourTo: number, minuteTo: number) => {
  return (hourTo * 60 + minuteTo) - (hourFrom * 60 + minuteFrom);
}

export const formatMinutes = (minutes: number): string => {
  return minutes <= 9 ? '0' + minutes : '' + minutes;
}

export const formatSimCardNumber = (imsi: string): string => {
  if (imsi.length < 12) {
    return imsi;
  }
  return `${imsi.substring(0,4)} ${imsi.substring(4,7)} ${imsi.substring(7,10)} ${imsi.substring(10,12)} ${imsi.substring(12)}`;
}

export const formatMoney = (unformattedMoney: number): string => {
  return `${(Math.round(unformattedMoney * 100) / 100).toFixed(2)}`.replace(".", ",");
}