import { IControl } from "mapbox-gl";
import TileTemplate from "../../ui/common/TileTemplate"
import classes from "./MapInfoTile.module.css"
import { renderToStaticMarkup } from "react-dom/server"

const MapInfoTileComponent = () => {
  return (
    <TileTemplate className={classes.mapInfo}>
        <div className={classes.container} style={{marginBottom: 8}}>
          <span className={classes.dot} style={{backgroundColor: "#AABCFD", opacity: 0.7}}></span><span className={classes.info}>Service verfügbar</span>
        </div>
        <div className={classes.container}>
          <span className={classes.dot}></span><span className={classes.info}>Nicht verfügbar</span>
        </div>
    </TileTemplate>
  )
}

class MapInfoTile implements IControl {
  private readonly _divElement;
  constructor () {
    this._divElement = document.createElement("div");
    this._divElement.setAttribute("class", "map-info-container");
  }

  onAdd(): HTMLElement {
    const staticElement = renderToStaticMarkup(<MapInfoTileComponent />);
    this._divElement.innerHTML = `${staticElement}`;
    return this._divElement;
  }
  onRemove(): void {
    this._divElement.remove();
  }
  getDefaultPosition?: (() => string) | undefined;
}

export default MapInfoTile