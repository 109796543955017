import { GeocodeAddress, reverseGeocodeToString } from '../../../store/workflow/MapSlice'
import { Order } from '../../../store/workflow/models'
import { getLat, getLng } from '../search/selectedPlace/SelectedPlace'

const AddressDetails = ({address, order}: {address?: GeocodeAddress, order: Order}) => {
  return (
    <div>
      <div>{reverseGeocodeToString(address)}</div>
      <div>{getLat(order.position?.point)}, {getLng(order.position?.point)}</div>
    </div>
  )
}

export default AddressDetails