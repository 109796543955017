import {ScaleTag} from "@telekom/scale-components-react";

interface StyledData {
    '--background': string,
    '--color': string,
}

export const StyledTag: Record<string, StyledData> = {
    Planned: {
        '--background': '#D3D7F9',
        '--color': '#6C3BDB',
    },
    Past: {
        '--background': '#E7E7E9',
        '--color': 'black',
    },
    Live: {
        '--background': '#FAD2CF',
        '--color': '#C30A03',
    },
    Cancelled: {
        '--background': 'black',
        '--color': 'white',
    },
    default: {
        '--background': '#E7E7E9',
        '--color': 'black',
    }
}

export const getStyledTag = (status: string | undefined) => {
    return <ScaleTag style={StyledTag[status ?? "default"]}>{status}</ScaleTag>;
}