import { useState } from 'react';
import {generateOrderConfirmationPDF} from "../api/backend/pdfApi";

export type GeneratingPDFState = Record<string, boolean>;
export type GeneratingPDFUrls = Record<string, string>;

const usePDFGeneration = (): [GeneratingPDFState, GeneratingPDFUrls, (orderId: string) => void] => {
    const [isGeneratingPDF, setIsGeneratingPDF] = useState<GeneratingPDFState>({});
    const [generatedPDFUrls, setGeneratedPDFUrls] = useState<GeneratingPDFUrls>({});

    const handlePDFRequest = (orderId: string) => {
        setIsGeneratingPDF((prevState) => {
            return {
                ...prevState,
                [orderId]: true
            }
        });
        generateOrderConfirmationPDF(orderId).then((blob) => {
            setIsGeneratingPDF((prevState) => {
                return {
                    ...prevState,
                    [orderId]: false
                }
            });

            setGeneratedPDFUrls((prevState) => {
                return {
                    ...prevState,
                    [orderId]: URL.createObjectURL(blob)
                }
            });
        });
    };

    return [isGeneratingPDF, generatedPDFUrls, handlePDFRequest];
};

export default usePDFGeneration;