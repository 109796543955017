import {useNotification} from "../../../../hooks/useNotification";
import {RootState, useAppSelector} from "../../../../store/ReduxStore";
import ModalTemplate from "../ModalTemplate";
import classes from "./Notification.module.css";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import {useRef} from "react";

const Notification = () => {
    const {message, open} = useAppSelector((state: RootState) => state.notificationReducer);
    const { clearNotification } = useNotification();
    const ref = useRef(null);

    const handleClickOutsideNotification = () => {
        clearNotification();
    };

    useOutsideClick(ref, handleClickOutsideNotification);

    return (
        <>
            {
                open ?
                    <ModalTemplate className={classes.notificationModal}>
                        <div className={`alert`} role="alert" ref={ref}>
                            <h4 className="alert-heading">Achtung!</h4>
                            <p>{message}</p>
                            <button className="telekom-button-magenta telekom-button-fat btn" onClick={clearNotification}
                                    style={{marginBottom: "26px", position: "absolute", right: 0}}>Ok</button>
                        </div>
                    </ModalTemplate>
                    : ""
        }
        </>
    )
}
export default Notification
