import TileTemplate from '../ui/common/TileTemplate'
import classes from "./ActionResult.module.css"
import { PropsReactChildren } from './model';

const ActionResult = ({children, isSuccess} : {children: PropsReactChildren, isSuccess: boolean}) => {
  const iconName = isSuccess ? "success" : "failure";
  const className = isSuccess ? classes.success : classes.failure;
  return (
    <TileTemplate className={`${classes.container} ${className}`}>
      <div className={classes.icon}><img src={
        // eslint-disable-next-line security/detect-non-literal-require
        require(`../../assets/icons/actionresult/${iconName}.png`)
      } alt="Action Icon" /></div>
      <div className={`${classes.info} teleNeo16`}>{children}</div>
    </TileTemplate>
  )
}

export default ActionResult