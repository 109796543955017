import { Order, OrderResponse } from "../../store/workflow/models";
import * as api from "../api";
import {authHeader} from "../apiHeader";

const productOrderUrl = `${process.env.REACT_APP_BACKEND_API_URL}/product-order`;

const cancelOrderUrl = `${process.env.REACT_APP_BACKEND_API_URL}/cancel-order`;

export const sendOrder = (order: Order) => api.authorizedPost<OrderResponse>(productOrderUrl, authHeader(), order);

export const cancelOrder = (orderId: string) => api.authorizedGet<void>(`${cancelOrderUrl}/${orderId}`, authHeader());