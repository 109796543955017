/* eslint-disable @typescript-eslint/no-explicit-any */
import pRetry from 'p-retry';
import { checkRetryStatus, errorHandler, getRetryAttempts } from './retryUtils';

const fetchPromise = async (
    url: string,
    method: string,
    header: Headers,
    body: unknown | null = null,
    resolve: (v: any) => void,
    responseMapper?: (res: Response) => Promise<any>) => {
    const res = await fetch(url, {
        method: method,
        headers: header,
        body: body !== null ? JSON.stringify(body) : null,
    })

    await checkRetryStatus(res, () => errorHandler(res, (msg) => new Error(msg)))

    if (res.status !== 204) {
        return resolve(responseMapper ? responseMapper(res) : res.json());
    } else {
        return resolve(null);
    }
}

const apiCall = (
    url: string,
    method: string,
    header: Headers,
    body: unknown | null = null,
    resolve: (v: any) => void,
    reject: (v: any) => void,
    responseMapper?: (res: Response) => Promise<any>
) => {
    return pRetry (() => fetchPromise(url, method, header, body, resolve, responseMapper),
        {
            retries: getRetryAttempts()
        }).catch(e => reject(safeJSONParse(e.message)))
}

const safeJSONParse = (message?: string) => {
    if (message === undefined) {
        return
    }
    try {
        return JSON.parse(message)
    } catch(e) {
        console.error(`Invalid JSON ${message}`)
    }
}

const headers = new Headers();
headers.append('Content-Type', 'application/json');

export const put = (url: string, body: unknown | null) =>
    new Promise((resolve, reject) => apiCall(url, 'PUT', headers, body, resolve, reject));

export const post = (url: string, body: unknown | null) =>
    new Promise<any>((resolve, reject) => apiCall(url, 'POST', headers, body, resolve, reject));

export const authorizedPost = <T,>(url: string, authToken: string, body: unknown | null, responseMapper?: (res: Response) => Promise<T>) => {
    headers.set("Authorization", authToken);
    headers.append("Access-Control-Allow-Origin", '*');
    return new Promise<T>((resolve, reject) => apiCall(url, 'POST', headers, body, resolve, reject, responseMapper));
}

export const get = (url: string) =>
    new Promise<any>((resolve, reject) => apiCall(url, 'GET', headers, null, resolve, reject));

export const authorizedGet = <T,>(url: string, authToken: string, responseMapper?: (res: Response) => Promise<T>) =>{
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.set('Authorization', authToken);
    headers.append('Access-Control-Allow-Origin', '*');
    return new Promise<T>((resolve, reject) => apiCall(url, 'GET', headers, null, resolve, reject, responseMapper));
}

export const authHeader = (token?: string | null) => {
    if (token) {
        return 'Bearer ' + token;
    } else {
        return '';
    }
}