import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { resetWorkflow } from './CommonActions';
export enum ApplicationState {
    PRODUCT_SELECTION_SCREEN, LEFT_PANEL_HIDDEN, SEARCH_SCREEN, TIME_AND_DATE_CARD_SCREEN, SUMMARY_SCREEN, SEND_ORDER_SCREEN, DASHBOARD_SCREEN
}

export const initialState = {
    applicationState: ApplicationState.PRODUCT_SELECTION_SCREEN,
    leftPanelDisabled: false
}

export const isWorkflowState = (tst: ApplicationState) =>
  [ApplicationState.LEFT_PANEL_HIDDEN, ApplicationState.SEARCH_SCREEN, ApplicationState.TIME_AND_DATE_CARD_SCREEN,
    ApplicationState.SUMMARY_SCREEN, ApplicationState.SEND_ORDER_SCREEN ].includes(tst);

const applicationState = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationState(state, action: PayloadAction<ApplicationState> ) {
      state.applicationState = action.payload;
    },
    goNext(state) {
      switch(state.applicationState) {
        case ApplicationState.PRODUCT_SELECTION_SCREEN:
          state.applicationState = ApplicationState.LEFT_PANEL_HIDDEN;
          break;
        case ApplicationState.SEARCH_SCREEN:
          state.applicationState = ApplicationState.TIME_AND_DATE_CARD_SCREEN;
          break;
        case ApplicationState.TIME_AND_DATE_CARD_SCREEN:
          state.applicationState = ApplicationState.SUMMARY_SCREEN;
          break;
        case ApplicationState.SUMMARY_SCREEN:
          state.applicationState = ApplicationState.SEND_ORDER_SCREEN;
          break;
      }
    },
    goBack(state) {
      switch(state.applicationState) {
        case ApplicationState.SEARCH_SCREEN:
          state.applicationState = ApplicationState.LEFT_PANEL_HIDDEN;
          break;
        case ApplicationState.TIME_AND_DATE_CARD_SCREEN:
          state.applicationState = ApplicationState.SEARCH_SCREEN;
          break;
        case ApplicationState.SUMMARY_SCREEN:
          state.applicationState = ApplicationState.TIME_AND_DATE_CARD_SCREEN;
          break;
        case ApplicationState.SEND_ORDER_SCREEN:
          state.applicationState = ApplicationState.SUMMARY_SCREEN;
          break;
      }
    },
    setLeftPanelDisabled(state, action: PayloadAction<boolean>) {
      state.leftPanelDisabled = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetWorkflow, () => initialState)
  },
})

export const { goNext, goBack, setApplicationState, setLeftPanelDisabled } = applicationState.actions
export default applicationState.reducer