import MapboxGeocoder, { Result, Results } from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import { addToSearchHistory, hideHistory, setIsPositionValid, setPositionAndAddress, setSuggestionsAmount } from "../../../store/workflow/MapSlice";
import { ApplicationState, setApplicationState } from "../../../store/ApplicationSlice";
import { RootState } from "../../../store/ReduxStore";
import { Action, CombinedState, ThunkDispatch } from "@reduxjs/toolkit";
import { MapWrapper } from "./MapWrapper";

export class GeocoderWrapper {
    private readonly _geocoder;

    constructor(dispatch: ThunkDispatch<CombinedState<RootState>, undefined, Action>, map: MapWrapper, mapAccessToken?: string) {
        this._geocoder = new MapboxGeocoder({
            accessToken: mapAccessToken ?? "",
            marker: false,
            countries: 'de',
            language: 'de',
            mapboxgl: mapboxgl,
            placeholder: 'Adresse suchen'
        });
        this._geocoder.on('result', async ({ result } : {result: Result}) => {
            const point = {
              lng: result.center[0],
              lat: result.center[1]
            };
            dispatch(setPositionAndAddress(point));
            dispatch(setIsPositionValid(await map.isPositionValid(point, dispatch) ?? false));
            dispatch(addToSearchHistory(result.place_name));
            dispatch(setSuggestionsAmount(0));
            dispatch(setApplicationState(ApplicationState.SEARCH_SCREEN));
        })
        this._geocoder.on('results', (results: Results) => {
          dispatch(setSuggestionsAmount(results.features.length));
        })
        this._geocoder.on("loading", ({ query }) => {
          if (query !== "") {
            dispatch(hideHistory(true));
          }
        })
        this._geocoder.on("clear", () => {
          dispatch(hideHistory(false));
          this._geocoder.query("");
        })
    }

    get geocoder() {
        return this._geocoder;
    }
}