import mapboxgl, {Marker} from 'mapbox-gl';
import {useEffect, useRef} from 'react';
import './MapComponent.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {useDispatch} from 'react-redux';
import {setIsPositionValid, setPositionAndAddress, setSearchQuery} from '../../store/workflow/MapSlice';
import {AppDispatch, useAppSelector} from '../../store/ReduxStore';
import {MapWrapper} from './mapWrapper/MapWrapper';
import {ApplicationState, setApplicationState} from '../../store/ApplicationSlice';
import {Point} from '../../store/workflow/models';

const MapComponent = ({initPosition} : {initPosition: Point}) => {
  const mapRef = useRef<MapWrapper | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const markerRef = useRef<Marker | null>(null);
  const disabledLayerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { position, searchQuery, isPositionValid } = useAppSelector((state) => state.mapReducer);
  const { applicationState } = useAppSelector((state) => state.applicationState);

  const enableMap = (enable: boolean) => {
    const marker = markerRef.current;
    const disabledLayer = disabledLayerRef.current;
    mapRef.current?.setEnabled(enable);

    if (marker) {
      marker.setDraggable(enable);
    }

    if (disabledLayer) {
      if (enable) {
        disabledLayer.classList.remove("disabledLayer");
      } else {
        disabledLayer.classList.add("disabledLayer");
      }
    }
  }
  
  useEffect(() => {
    if (mapRef.current || mapContainerRef.current == null) return; // initialize map only once

    const positionMarker = new mapboxgl.Marker({
      draggable: true
    });

    mapRef.current = new MapWrapper(mapContainerRef.current, initPosition, dispatch, positionMarker);
    const map = mapRef.current.map;
    positionMarker.setLngLat(initPosition).addTo(map);

    document.getElementsByClassName("mapboxgl-marker")[0].insertAdjacentElement('afterbegin', createDivWithSpinner());
     
    const onDragEnd = async () => {
        dispatch(setPositionAndAddress({
            lat: positionMarker.getLngLat().lat,
            lng: positionMarker.getLngLat().lng
        }));
        dispatch(setApplicationState(ApplicationState.SEARCH_SCREEN));
        dispatch(setIsPositionValid(await mapRef.current?.isPositionValid(positionMarker.getLngLat(), dispatch) ?? false));
    }
    positionMarker.on('dragend', onDragEnd);
    markerRef.current = positionMarker;
  });

  const createDivWithSpinner = () : HTMLDivElement => {
    const spinner = document.createElement('div');
    spinner.id = 'spinnerDiv';
    spinner.classList.add("spinner-border");
    spinner.style.display = 'none';
    return spinner;
  }


  useEffect(() => {
    const marker = markerRef.current;
    if (marker !== null && position !== undefined) {
        marker.setLngLat(position);
        mapRef.current?.updateCircle(isPositionValid)
    }
  }, [position, isPositionValid, dispatch])

  useEffect(() => {
    if (searchQuery != null) {
        mapRef.current?.query(searchQuery);
    }
    dispatch(setSearchQuery(null));
  }, [searchQuery, dispatch])

  useEffect(() => {
    enableMap([ApplicationState.LEFT_PANEL_HIDDEN, ApplicationState.SEARCH_SCREEN].includes(applicationState));
  }, [applicationState])

  return (
    <>
      <div ref={mapContainerRef} className="map-container">
        <div ref={disabledLayerRef} />
      </div>
    </>
  )
}

export default MapComponent