import { useEffect, useRef } from "react";
import { useAppSelector } from "../../../store/ReduxStore";
import classes from "./MapSuggestions.module.css"

const MapSuggestions = () => {
  const suggestionsAmount = useAppSelector((state) => state.mapReducer.suggestionsAmount);
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (searchContainerRef.current) {
      searchContainerRef.current.style.paddingBottom = `${suggestionsAmount * 55}px`;
    }
  }, [suggestionsAmount])
  return (
    (suggestionsAmount > 0) ? <div className={classes.container} ref={searchContainerRef}>Ergebnisse</div> : <></>
  )
}

export default MapSuggestions