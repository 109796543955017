import classes from "./DatePicker.module.css";
import {ScaleDatePicker} from "@telekom/scale-components-react";
import {Dispatch, SetStateAction, useMemo, useState} from "react";
import {ScaleDatePickerCustomEvent} from "@telekom/scale-components";
import moment from "moment-timezone";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/ReduxStore";
import {ProductDTO} from "../../../../store/workflow/models";
import {getSelectedProductSelector} from "../../../../store/workflow/ProductSlice";

export declare type DuetDatePickerChangeEvent = {
    component: "duet-date-picker";
    valueAsDate: Date;
    value: string;
};

const localization = {
    buttonLabel: '',
    placeholder: '',
    selectedDateMessage: 'Gewähltes Datum',
    prevMonthLabel: 'Vorheriger Monat',
    nextMonthLabel: 'Nächster Monat',
    monthSelectLabel: 'Monat',
    yearSelectLabel: 'Jahr',
    closeLabel: 'Fenster schließen',
    keyboardInstruction:
        'Sie können mit den Pfeiltasten vor und zurück navigieren',
    calendarHeading: 'Datum wählen',
    dayNames: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
    ] as [string, string, string, string, string, string, string],
    monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
    ] as [string, string, string, string, string, string, string, string, string, string, string, string],
    monthNamesShort: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
    ] as [string, string, string, string, string, string, string, string, string, string, string, string],
    today: 'heute',
    locale: 'de-DE',
};

const DatePicker = ({spanLabel, dateValue, setSelectedDate, invalid}: {spanLabel: string, dateValue: string, setSelectedDate: Dispatch<SetStateAction<string>>, invalid: boolean}) => {
    const [label, setLabel] = useState<string>("Heute");
    const selectedProduct = useSelector<RootState, ProductDTO | undefined>(getSelectedProductSelector);
    const activationLeadTime = moment.duration(selectedProduct?.activationLeadTime?.value).asSeconds();

    const {year, month, day} = useMemo(() => {
        if (dateValue === undefined || dateValue === '') {
            const date = new Date();
            date.setSeconds(date.getSeconds() + activationLeadTime);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const day = date.getDate().toString().padStart(2, "0");
            setSelectedDate(year + '-' + month + '-' + day);
            return {year, month, day}
        }
        const date = moment(dateValue);
        return {date};
    }, [])

    const handleChange = (event: ScaleDatePickerCustomEvent<DuetDatePickerChangeEvent>) => {
        if (event.detail && event.detail.value) {
            setLabel(isSelectedToday(new Date(event.detail.value)) ? "Heute" : "");
            setSelectedDate(event.detail.value);
        }
    };

    const isSelectedToday = (selectedDate: Date): boolean=> {
        const today = new Date();

        if (today.getDay() === selectedDate.getDay()
            && today.getMonth() === selectedDate.getMonth()
            && today.getFullYear() === selectedDate.getFullYear())
            return true;

        return false;
    }

    return (
        <div className={classes.container}>
            <span className='eyebrow teleNeo16'>{spanLabel}</span>
            <ScaleDatePicker
                className={classes.dataPicker}
                label={label}
                localization={localization}
                onScaleChange={handleChange}
                min={year + '-' + month + '-' + day}
                value={dateValue}
                invalid={invalid}
            >
            </ScaleDatePicker>
        </div>
    )
}

export default DatePicker